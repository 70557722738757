import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection'
import InsuranceSection from './components/InsuranceSection'
import ContactForm from './components/ContactForm'
import PartnershipSection from './components/PartnershipSection';
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/Footer'
import FAQPage from './pages/FAQPage';
import ResourcesPage from './pages/ResourcesPage';
import BlogPage from './pages/BlogPage';
import BookPage from './pages/BookPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection/>
              <InsuranceSection/>
              <AboutSection/>
              <PartnershipSection/>
              <ContactForm/>
            </>
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/resources" element={<ResourcesPage/>} />
          <Route path="/faqs" element={<FAQPage />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path='/book' element = {<BookPage/>}/>

        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;