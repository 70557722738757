import React, { useEffect } from 'react';
import { Construction } from 'lucide-react';


const ElfsightWidget = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div 
      className="elfsight-app-a13c8e65-40c8-478f-904c-693588b55b2f" 
      data-elfsight-app-lazy 
    />
  );
};



const BlogPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="mx-auto">
          <div className="text-center">
            <h1 className="text-3xl font-medium text-gray-900 mb-4">
              Transitions Blog
            </h1>
            <p className="text-gray-600 mb-8">
              Insights, resources, and updates from our mental health professionals
            </p>
          </div>

          {/* Coming Soon Section */}
          <ElfsightWidget/>
         
        </div>
      </div>
    </div>
  );
};

export default BlogPage;