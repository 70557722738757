import React from "react";

const BookPage = () => {
  return (
    <section id="about" className="bg-white py-16">
      <div className="container mx-auto px-4">
        <iframe
          width="100%"
          height="1000"
          src="https://ehr.charmtracker.com/publicCal.sas?method=getCal&digest=edb5a5bb29aedb5a6ad71e51d09a1c8c93374c6c1e2ee3106b13a1edc98ae006ef59d40e1bb127af5377deefbdc0f28bc2fe0580a6189219"
          frameborder="0"
        ></iframe>
      </div>
    </section>
  );
};

export default BookPage;
